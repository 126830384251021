.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  z-index: 1000;
}

.whatsapp-button img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
