@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
* {
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer h2 {
  font-weight: normal;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-evenly;
}

.contact-us {
  margin-top: 16px;
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;
}
.social-icons li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 1.2em;
}
.social-icons i {
  color: #fff;
  position: absolute;
  top: 10px;
  left: 13px;
  transition: all 265ms ease-out;
}
.social-icons a {
  display: inline-block;
}
.social-icons a:before {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  content: " ";
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: block;
  background: linear-gradient(45deg, #00b5f5, #002a8f);
  transition: all 265ms ease-out;
}
.social-icons a:hover:before {
  transform: scale(0);
  transition: all 265ms ease-in;
}
.social-icons a:hover i {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.6);
  color: #00b5f5;
  background: -webkit-linear-gradient(45deg, #00b5f5, #002a8f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 265ms ease-in;
}

.contact-us active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

.contact-us ::before,
::after {
  position: absolute;
  content: "";
}

.btn {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px 25px 15px;
  min-width: 150px;
}
.btn span {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  transition: 0.3s;
}

.btn-4 span {
  color: rgb(28, 31, 30);
  background-color: rgb(245, 245, 245);
}
.btn-4 span:hover {
  color: rgb(54, 56, 55);
}
.btn-4::before,
.btn-4::after {
  width: 15%;
  height: 2px;
  background-color: rgb(54, 56, 55);
  z-index: 2;
}

.btn.hover-border-7::before,
.btn.hover-border-7::after {
  bottom: 0;
  transition: width 0.2s 0.35s ease-out;
}
.btn.hover-border-7::before {
  right: 50%;
}
.btn.hover-border-7::after {
  left: 50%;
}
.btn.hover-border-7:hover::before,
.btn.hover-border-7:hover::after {
  width: 50%;
  transition: width 0.2s ease-in;
}

.btn.hover-border-7 span::before,
.btn.hover-border-7 span::after {
  width: 0%;
  height: 0%;
  background: transparent;
  opacity: 0;
  z-index: 2;
  transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}
.btn.hover-border-7 span::before {
  bottom: 0;
  left: 0;
  border-left: 2px solid rgb(54, 56, 55);
  border-top: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-7 span::after {
  bottom: 0;
  right: 0;
  border-right: 2px solid rgb(54, 56, 55);
  border-top: 2px solid rgb(54, 56, 55);
}
.btn.hover-border-7 span:hover::before,
.btn.hover-border-7 span:hover::after {
  width: 50%;
  height: 96%;
  opacity: 1;
  transition: height 0.2s 0.2s ease-in, width 0.2s 0.4s linear, opacity 0s 0.2s;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .contacts {
    align-items: center;
  }
}
