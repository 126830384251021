.news-content {
  border-bottom: solid 1px black;
  margin: 2% 10%;
}

.news-image-container {
  display: flex;
  justify-content: center;
  margin: 2% 10%;
}

.news-image {
  height: 400px;
  width: auto;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-container iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  border: none;
  border-radius: 8px;
}
